import React, { useEffect } from 'react';
import servisorIcon from '../../../assets/servisorWhiteLogo.png';
import CheckBox from '../../../assets/icons/checkBox.svg';
import vehicleTag from '../../../assets/tags/servicetagnew.svg';
import dataAnimation from '../../../assets/lottie/Servisor_03_Service Plan.json';
import NextIcon from '../../../assets/icons/ChevronRightFilled.svg';

import {
    Typography,
    Box,
    Container,
    Button,
    Tooltip,
    RadioGroup,
    Radio,
    FormControlLabel,
    BottomNavigation,
    Paper,
    Popover,
} from '@mui/material';

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ServiceDetailsModel } from '../../../models/ServiceDetailsModel';
import { VehicleDetailsModel } from '../../../models/VehicleDetailsModel';
import { useGlobalContext } from '../../../services/context/globalContext';
import { HeaderText } from '../../../components/common/HeaderText';
import { SubHeaderText } from '../../../components/common/SubHeaderText';
import ProgressBar from '../../../components/common/ProgressBar';
import { PageLayout } from '../../../components/layout/PageLayout';
import IssueHelpButton from '../../../components/common/IssueHelpButton';

export const ServicePlanSlide: React.FC<any> = () => {
    const { registrationNumber, milleage } = useGlobalContext();
    const [selectedServicePlan, setSelectedServicePlan] = useState('');

    const [vehicleDetails, setVehicleDetails]: any = useState(
        new VehicleDetailsModel({})
    );
    const [serviceDetails, setServiceDetails] = useState(
        new ServiceDetailsModel({})
    );
    const history = useHistory();

    const fetchVehicleDetails = (): void => {
        const vehicleData = JSON.parse(localStorage.getItem("vehicle-details") ?? "")
        if (vehicleData && vehicleData.vehicle_make) {
            console.log("getting th data: ", vehicleData)
            setVehicleDetails(vehicleData);
        }
    }

    useEffect(() => {
        fetchVehicleDetails();
    }, [])

    const assignVehicle = (ev: any, target: string) => {
        switch (target) {
            case 'registration_number':
                vehicleDetails.RegistrationNumber = registrationNumber;
                serviceDetails.registration_number = registrationNumber;
                break;
            case 'milleage':
                serviceDetails.milleage = milleage;
                break;
            case 'service_plan':
                serviceDetails.service_plan = ev.target.value
                    ? ev.target.value
                    : null;
                break;
        }
        setServiceDetails(serviceDetails);
    };

    localStorage.setItem('ServicePlan', JSON.stringify(selectedServicePlan));

    const saveVehicle = () => {
        const getService  = JSON.parse(localStorage.getItem('ServicePlan') ?? "");

        if (['bmw', 'bmw motorad', 'mini', 'mercedes commercial', 'mercedes', 'mercedes trucks'].includes(vehicleDetails.vehicle_make?.toLowerCase())) {
            history.push("/workshop-listings");
            return;
        }

        if (getService === "false") {
          history.push("/onboarding/4");
        }
         else if (getService === "true") {
          history.push("/workshop-listings");
        } else {
          return;
        }
      };



      const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

    return (
        <PageLayout animationData={dataAnimation}>
                            <HeaderText
                    headerText='VEHICLE DETAILS'
                    headerImage={servisorIcon}
                />

            <Box
                sx={{
                    backgroundColor: '#FFF',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 64px)',
                    paddingBottom: '100px',
                    position: 'relative',
                    paddingTop: { xs: '50px', lg: '100px' },
                    display: 'flex',
                    alignSelf: { xs: 'normal', lg: 'center' },
                    flexDirection: { xs: 'column', lg: 'column' },
                }}
            >
                <Box
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        alignSelf: 'center',
                        width: { xs: '100%', lg: '70%' },
                    }}
                >
                    <img src={vehicleTag} alt='/' />
                </Box>

                <SubHeaderText headerText='Is the vehicle currently on a maintenance or service plan? ' />

                <Container
                    disableGutters
                    sx={{
                        width: { xs: '90%', lg: '70%' },
                        marginTop: { xs: '-10px', lg: '0' },
                        borderRadius: { xs: '4px', lg: '4px' },
                        backgroundColor: '#FFF',
                        paddingX: { xs: '20px', lg: '0' },
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: { xs: 'flex', lg: 'none' },
                                alignSelf: 'left',
                                width: { xs: '100%', lg: '70%' },
                                paddingTop: { xs: '20px', lg: '0' },
                            }}
                        >
                            <img src={vehicleTag} alt='/' />
                        </Box>
                        <form>
                            <Box sx={{ display: 'flex', width: '100%' }}>
                                <RadioGroup
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    // value={serviceDetails.service_plan}
                                    value={selectedServicePlan}
                                    onChange={(ev) => {
                                        setSelectedServicePlan(ev.target.value);
                                        assignVehicle(ev, 'service_plan');
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '10px',
                                            flexDirection: 'column',
                                            py: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    value='false'
                                                    name='false'
                                                    icon={
                                                        <Box
                                                            sx={{
                                                                border: '1px solid var(--gray-300, #D0D5DD)',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius:
                                                                    '10px',
                                                            }}
                                                        ></Box>
                                                    }
                                                    checkedIcon={
                                                        <img
                                                            src={CheckBox}
                                                            alt='tick'
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label='                    No, it’s not on a plan'
                                            sx={{
                                                margin: '0px',

                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                borderRadius: '8px',
                                                padding: '16px',
                                                color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',

                                                border:
                                                    serviceDetails.service_plan ===
                                                    'false'
                                                        ? '1px solid #004FFA'
                                                        : '1px solid var(--gray-200, #EAECF0)',
                                                backgroundColor:
                                                    serviceDetails.service_plan ===
                                                    'false'
                                                        ? '#E9F0FF'
                                                        : '',
                                            }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    value='true'
                                                    name='true'
                                                    icon={
                                                        <Box
                                                            sx={{
                                                                border: '1px solid var(--gray-300, #D0D5DD)',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius:
                                                                    '10px',
                                                            }}
                                                        ></Box>
                                                    }
                                                    checkedIcon={
                                                        <img
                                                            src={CheckBox}
                                                            alt='tick'
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            label='Yes - it is currently on a plan'
                                            sx={{
                                                margin: '0px',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                flexGrow: '2',
                                                borderRadius: '8px',
                                                padding: '16px',
                                                border:
                                                    serviceDetails.service_plan ===
                                                    'true'
                                                        ? '1px solid #004FFA'
                                                        : '1px solid var(--gray-200, #EAECF0)',
                                                backgroundColor:
                                                    serviceDetails.service_plan ===
                                                    'true'
                                                        ? '#E9F0FF'
                                                        : '',
                                                color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                                            }}
                                        />
                                    </Box>
                                </RadioGroup>
                            </Box>

                            <Box sx={{ display: 'flex', alignSelf: 'start' }}>
                                {/* <Tooltip
                                    disableFocusListener
                                    title='A maintenance and service plan covers the costs involved with regular servicing of your vehicle as stipulated by the manufacturer.

Servisor is not able to claim on your behalf or refund you in this case.'
                                    placement='top'
                                    enterDelay={0}
                                >
                                    <Button>
                                        <Typography
                                            sx={{
                                                textTransform: 'none',
                                                color: '#1B1B1B',
                                            }}
                                        >
                                            Why do we ask?
                                        </Typography>
                                    </Button>
                                </Tooltip> */}
                                <Button aria-describedby={id} variant="text" onClick={handleClick} sx={{textTransform: 'unset', color: '#1B1B1B'}}>
                                Why do we ask?

</Button>
<Popover
  id={id}
  open={open}
  anchorEl={anchorEl}
  onClose={handleClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
>
    <Button onClick={handleClose} sx={{textTransform: 'unset', color: '#1B1B1B', display: 'flex', justifySelf: 'end'}} >Close</Button>

<Box sx={{display: {xs: 'none', lg: 'block'}}}>
<Typography sx={{ p: 2, textTransform: 'unset', color: '#1B1B1B' }}>A maintenance and service plan covers the costs <br /> involved with regular servicing of your vehicle as<br /> stipulated by the manufacturer.</Typography>
<Typography sx={{ px: 2, pb: 2, textTransform: 'unset', color: '#1B1B1B' }}>Servisor is not able to claim on your behalf or refund<br /> you in this case.</Typography>

</Box>
<Box sx={{display: {xs: 'block', lg: 'none'}}}>
<Typography sx={{ p: 2, textTransform: 'unset', color: '#1B1B1B' }}>A maintenance and service plan covers the costs  involved with regular servicing of your vehicle as stipulated by the manufacturer.</Typography>
<Typography sx={{ px: 2, pb: 2, textTransform: 'unset', color: '#1B1B1B' }}>Servisor is not able to claim on your behalf or refund you in this case.</Typography>

</Box>
</Popover>
                            </Box>
                            <IssueHelpButton />
                            {['bmw', 'bmw motorad', 'mini', 'mercedes commercial', 'mercedes', 'mercedes trucks'].includes(vehicleDetails.vehicle_make?.toLowerCase()) && (
                                <Box sx={{mt: 3, p:2, border: "1px solid #cc", borderRadius: "8px", backgroundColor: "#f9f9f9"}}>
                                    <Typography variant="body1" sx={{color: "#333"}}>
                                    <strong>No price available</strong>. BMW, Mercedes-Benz, Mini  Cooper and Smart use your vehicle's data to determine when a service is needed, there are no set intervals. Click next to still view a list of service providers.
                                    </Typography>
                                </Box>
                            )}

                            <Paper
                                sx={{
                                    position: 'fixed',
                                    bottom: 0,
                                    right: 0,
                                    paddingY: '10px',
                                    zIndex: '999',
                                    width: { xs: '100%', lg: '50%' },
                                    borderRadius: '0',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                                elevation={0}
                            >
                                <BottomNavigation
                                    sx={{
                                        width: { xs: '100%', lg: '70%' },

                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Box
                                        py={3}
                                        sx={{
                                            display: { xs: 'flex' },
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Box>
                                            <Button
                                                onClick={() =>
                                                    history.push(
                                                        '/onboarding/2'
                                                    )
                                                }
                                                sx={{
                                                    padding: '0',
                                                    margin: '0',
                                                    display: 'flex',
                                                    borderRadius: '0',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={NextIcon}
                                                        alt='/'
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            transform:
                                                                'rotate(180deg)',
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '13px',
                                                        fontWeight: '700',
                                                        color: '#101828',
                                                    }}
                                                >
                                                    Back
                                                </Typography>{' '}
                                            </Button>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <ProgressBar value={75} />
                                        </Box>
                                        <Box>
                                            
                                            <Button
                                                disabled={!selectedServicePlan}
                                                onClick={() => saveVehicle()}
                                                sx={{
                                                    padding: '0',
                                                    margin: '0',
                                                    display: 'flex',
                                                    borderRadius: '0',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '13px',
                                                        fontWeight: '700',

                                                        color: !selectedServicePlan
                                                            ? 'grey'
                                                            : '#101828',
                                                    }}
                                                >
                                                    Next
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={NextIcon}
                                                        alt='/'
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    />
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Box>
                                </BottomNavigation>
                            </Paper>
                        </form>
                    </Box>
                </Container>
            </Box>
        </PageLayout>
    );
};
