import React from 'react';
import dataAnimation from '../../../assets/lottie/Servisor_01_Personal Details.json';
import NextIcon from '../../../assets/icons/ChevronRightFilled.svg';

import {
    Typography,
    Button,
    Container,
    CircularProgress,
    Box,
    BottomNavigation,
    Paper,
} from '@mui/material';

import personalTag from '../../../assets/tags/personaltagnew.svg';
import servisorIcon from '../../../assets/servisorWhiteLogo.png';
import { UserDetailsModel } from '../../../models/UserDetailsModel';
import { useState } from 'react';
import { emsAuth } from '../../../services/apis/ems.service';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../services/context/globalContext';
import TextField from '@mui/material/TextField';
import { HeaderText } from '../../../components/common/HeaderText';
import { SubHeaderText } from '../../../components/common/SubHeaderText';
import ProgressBar from '../../../components/common/ProgressBar';
import { PageLayout } from '../../../components/layout/PageLayout';
import IssueHelpButton from '../../../components/common/IssueHelpButton';
import Loader from '../../../components/Loading/Loading';

/**
 * Renders the DetailsEvaluationPage component, responsible for collecting user details during onboarding.
 * @param {object} userDetails - The user's details.
 * @param {function} setUserDetails - A function to set the user's details.
 * @returns {JSX.Element} - The rendered component.
 */



export const DetailsEvaluationPage: React.FC<any> = () => {
    const {
        setbookingId,
        bookingId: bi,
        setUserDetailState,
    } = useGlobalContext();
    const [userDetails, setUserDetails] = useState(new UserDetailsModel({}));

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    // Regular expression patterns for mobile number and email validation

    /**
     * Checks the validity of the entered phone number and updates mobilevalidated state.
     * @param {object} onboardEvent - The event object.
     */
    const [nameError, setNameError] = useState('');
    const [surNameError, setSurNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');

    const assignUserDetails = (onboardEvent: any, target: string) => {
        switch (target) {
            case 'name':
                const name = onboardEvent.target.value;
                if(name.startsWith(' ')) {
                   setNameError('Name should not start with a space.'); 
                }
                else{
                    setNameError('');
                }
                userDetails.name = name ? name : null;
                break;
            case 'surname':
                const surname = onboardEvent.target.value;
                if(surname.startsWith(' ')) {
                setSurNameError('Surname should not start with a space.');
                }
                else{
                    setSurNameError('');
                }
                userDetails.surname = surname ? surname : null;
                break;

                case 'mobile':
                    const mobile = onboardEvent.target.value;
                    if (!/^\+?\d*$/.test(mobile)) {
                        setMobileError('Number invalid.');
                    } else if (mobile.startsWith('0') && mobile.length !== 10) {
                        setMobileError('Number should have 10 digits.');
                    } else if (mobile.startsWith('+27') && mobile.length !== 12) {
                        setMobileError('Number should have 11 digits.');
                    } else if (!mobile.startsWith('0') && !mobile.startsWith('+27')) {
                        setMobileError('Number should start with 0 or +27.');
                    } else {
                        setMobileError('');
                    }
                    userDetails.phonenumber = mobile ? mobile : null;
                    break;
            case 'email':
                if (
                    onboardEvent.target.value &&
                    !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
                        onboardEvent.target.value
                    )
                ) {
                    setEmailError('Please enter a valid email.');
                } else {
                    setEmailError('');
                }
                userDetails.email = onboardEvent.target.value
                    ? onboardEvent.target.value
                    : null;
                break;
        }
        setUserDetails(new UserDetailsModel(userDetails));
    };

    /**
     * Signs up the user, saves necessary data in global state, and navigates to the next page.
     */
    const signupUser = () => {
        setLoading(true);
        const bookingId: string = uuid();
        setbookingId(bookingId);

        localStorage.setItem('details-evaluation', JSON.stringify(userDetails));

        setUserDetailState(JSON.stringify(userDetails));

        emsAuth().then((res) => {
            localStorage.setItem('ems-auth-token', res.access_token);
            setLoading(false);
            history.push('/onboarding/2');
        });
    };


    return (
        <PageLayout animationData={dataAnimation}>
           
                           
              <HeaderText
                    headerText='PERSONAL DETAILS'
                    headerImage={servisorIcon}
                /> 
            <Box
                sx={{
                    backgroundColor: '#FFF',
                    overflow: 'auto',
                    maxHeight: {xs: '100vh', lg:'calc(100vh - 64px)'},
                    position: 'relative',
                    paddingTop: { xs: '50px', lg: '82px' },
                    paddingBottom: { xs: '50px', lg: '0x' },
                    display: 'flex',
                    alignSelf: { xs: 'normal', lg: 'center' },
                    flexDirection: { xs: 'column', lg: 'column' },
                }}
            >
                <Box
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        alignSelf: 'center',
                        width: { xs: '100%', lg: '70%' },
                    }}
                >
                    <img src={personalTag} alt='/' />
                </Box>
              
                <SubHeaderText headerText="We’ll need a few details from you to get started" />

                <Container
                    sx={{
                        // overflowY: { xs: 'scroll', lg: 'hidden' },
                        height: { xs: '100%', lg: '100%' },
                        // display: 'flex',
                        // justifyContent: { xs: 'center', lg: 'end' },

                        width: { xs: '90%', lg: '70%' },
                        marginTop: { xs: '-10px', lg: '0' },
                        paddingTop: { xs: '0', lg: '10px' },
                        borderRadius: { xs: '4px', lg: '4px' },
                        paddingBottom: { xs: '50px', lg: '0' },
                        // zIndex: 999,
                        backgroundColor: '#FFF',
                        paddingX: { xs: '20px', lg: '0' },
                        // flexDirection: { xs: 'column', lg: 'unset' },
                    }}
                    disableGutters
                >
                    
                    <form onSubmit={signupUser} style={{ width: '100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '50px',
                                width: { xs: '100%', lg: '90%' },
                            }}
                        >
                            <Box
                                sx={{
                                    paddingTop: '5px',
                                    display: 'flex',
                                    gap: '13px',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: { xs: 'flex', lg: 'none' },
                                        alignSelf: 'left',
                                        width: { xs: '100%', lg: '70%' },
                                        paddingTop: { xs: '10px', lg: '0' },
                                    }}
                                >
                                    <img src={personalTag} alt='/' />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '2px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--gray-700, #344054)',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        First Name
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            sx: { borderRadius: '8px' },
                                        }}
                                        fullWidth
                                        variant='outlined'
                                        placeholder='Enter your first name'
                                        id='fullWidth'
                                        value={userDetails.name || ''}
                                        error={!!nameError}
                                        helperText={nameError}
                                        onChange={(ev) => {
                                            assignUserDetails(ev, 'name');
                                        }}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '2px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--gray-700, #344054)',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Surname
                                    </Typography>

                                    <TextField
                                        InputProps={{
                                            sx: { borderRadius: '8px' },
                                        }}
                                        fullWidth
                                        placeholder='Enter your surname'
                                        id='fullWidth'
                                        value={userDetails.surname || ''}
                                        error={!!surNameError}
helperText={surNameError}
                                        onChange={(ev) => {
                                            assignUserDetails(ev, 'surname');
                                        }}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '2px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--gray-700, #344054)',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Mobile
                                    </Typography>

                                    <TextField
                                        InputProps={{
                                            sx: { borderRadius: '8px' },
                                        
                                        }}
                                        fullWidth
                                        id='fullWidth'
                                        type='tel'
                                        placeholder='Enter your mobile'
                                        error={!!mobileError}
                                        helperText={mobileError}
                                        value={userDetails.phonenumber || ''}
                                        onChange={(ev) => {
                                            assignUserDetails(ev, 'mobile');
                                        }}
                                        
                                        // inputProps={{ pattern: "\\(\\d{3}\\) \\d{3}-\\d{4}",  }}
                                    />



      

                                    
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '2px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--gray-700, #344054)',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Email
                                    </Typography>

                                    <TextField
                                        InputProps={{
                                            sx: { borderRadius: '8px' },
                                        }}
                                        fullWidth
                                        type='email'
                                        placeholder='Enter your email'
                                        value={userDetails.email || ''}
                                        error={!!emailError}
                                        helperText={emailError}
                                        onChange={(ev) => {
                                            assignUserDetails(ev, 'email');
                                        }}
                                    />
                                </Box>
                            <Box>
                                    <Typography>
                                    By clicking 'Next' you agree to the  <a href='https://www.servisor.co.za/legal/terms-and-conditions' target='__blank' style={{textDecoration: 'none'}}>Ts & Cs.</a>
                                    </Typography>
                                </Box>

                              <IssueHelpButton />

                            </Box>
                       
                        </Box>
                                                            
                        <Paper
                            sx={{
                                position: { xs: 'fixed', lg: 'fixed' },
                                bottom: 0,
                                right: 0,
                                zIndex: '99',
                                paddingY: '20px',
                                borderRadius: ' 0',
                                display: 'flex',
                                width: { xs: '100%', lg: '50%' },
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            elevation={0}
                        >


                            <BottomNavigation
                                sx={{ width: { xs: '100%', lg: '70%' } }}
                            >
                                <Box
                                    py={3}
                                    sx={{
                                        display: { xs: 'flex' },
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Box>
                                        <Button
                                            onClick={() =>
                                                history.push('/home')
                                            }
                                            sx={{
                                                padding: '0',
                                                margin: '0',
                                                display: 'flex',
                                                borderRadius: '0',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={NextIcon}
                                                    alt='/'
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        transform:
                                                            'rotate(180deg)',
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    fontWeight: '700',
                                                    color: '#101828',
                                                }}
                                            >
                                                Back
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ProgressBar value={25} />
                                    </Box>
                                    <Box>
                                        <Button
                                            disabled={
                                                !userDetails.name ||
                                                !userDetails.surname ||
                                                !userDetails.phonenumber ||
                                                !userDetails.email ||
                                                !!nameError ||
                                                !!surNameError ||
                                                !!mobileError ||
                                                !!emailError
                                            }
                                            sx={{
                                                padding: '0',
                                                margin: '0',
                                                display: 'flex',
                                                borderRadius: '0',
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={() => signupUser()}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    fontWeight: '700',
                                                    color:
                                                        !userDetails.name ||
                                                        !userDetails.surname ||
                                                        !userDetails.phonenumber ||
                                                        !userDetails.email ||
                                                        !!nameError ||
                                                        !! surNameError ||
                                                        !!mobileError ||
                                                        !!emailError
                                                            ? 'grey'
                                                            : '#101828',
                                                }}
                                            >
                                                Next
                                            </Typography>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color:
                                                        !userDetails.name ||
                                                        !userDetails.surname ||
                                                        !userDetails.phonenumber ||
                                                        !userDetails.email ||
                                                        !!nameError ||
                                                        !!mobileError ||
                                                        !!emailError
                                                            ? 'grey'
                                                            : '#101828',
                                                }}
                                            >
                                                <img
                                                    src={NextIcon}
                                                    alt='/'
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            </Box>
                                        </Button>
                                    </Box>
                                </Box>
                            </BottomNavigation>
                        </Paper>
                    </form>
                </Container>
            </Box>
            {loading &&  (
                            <Loader />
                        )
                    }
        </PageLayout>
    );
};
