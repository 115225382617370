// ShopDetail.js
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    Popover,
    Toolbar,
    Typography,
} from '@mui/material';
import Stars from '../../../assets/5Stars_Blue@1x.svg';
import { PageLayout } from '../../../components/layout/PageLayout';
import dataAnimation from '../../../assets/lottie/Servisor_09_Confirmation_1.json';
import FilterBanner from '../../results/components/filterBanner';
import ListedShops from '../../results/components/listedShops';
import { useCallback, useEffect, useState } from 'react';
import filterIcon from '../../../assets/icons/filter_list.svg';
import { getAllActiveWorkshops } from '../../../services/apis/worksshopsData.service';
import SingleShopLists from './singleShopLists';
import IssueHelpButton from '../../../components/common/IssueHelpButton';

/**
 * Component to render single shop card using details given
 *
 * @param index
 * @param shopdetails
 */
// {index, shopDetails}: { index:any; shopDetails:any}
interface Vehicle {
    name: string;
}
interface Workshop {
    isWeekdayWorking: boolean;
    isSaturdayWorking: boolean;
    isSundayWorking: boolean;
    vehicles: Vehicle[];
}

export const ShopDetail = () => {
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const [workshopsData, setWorkshopsData] = useState({});
    const [listedShops, setListedShops] = useState([]);
    const [showActionSheet, setShowActionSheet] = useState(false);
    const [filterText, setFilterText] = useState('Savings - (High to Low)');
    const [workshopVehicle, setWorkshopVehicles] = useState<Workshop[]>([]);
    const [vehicleChosen, setVehicleChosen] = useState('');

    const [filterWeekday, setFilterWeekday] = useState(false);
    const [filterSaturday, setFilterSaturday] = useState(false);
    const [filterSunday, setFilterSunday] = useState(false);

    const getActiveWorkshops = useCallback(() => {
        setIsLoading(true);

        getAllActiveWorkshops()
            .then((response) => {
                const vehicleDetails = JSON.parse(
                    localStorage.getItem('vehicle-details') ?? ''
                );

                const workshopData = response;

                const workshopsVehicles = workshopData?.data.map(
                    (workshop: any) => {
                        return workshop;
                    }
                );

                setVehicleChosen(vehicleDetails.vehicle_make);

                setWorkshopVehicles(workshopsVehicles);
                localStorage.setItem(
                    'selected-workshop-details',
                    JSON.stringify(workshopData)
                );

                setWorkshopsData(workshopData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching active workshops: ', error);
                setIsLoading(false);
            });
    }, []);

    const filteredWorkshops: Workshop[] = workshopVehicle.filter(
        (workshop: Workshop) => {
            const vehicleWorkshop = workshop.vehicles.map((vehicle: any) =>
                vehicle.name.toLowerCase()
            );
            const matchesVehicle = vehicleWorkshop.includes(
                vehicleChosen.toLowerCase()
            );
            const anyFilterActive =
                filterWeekday || filterSaturday || filterSunday;

            const matchesFilters =
                !anyFilterActive ||
                (filterWeekday && workshop.isWeekdayWorking) ||
                (filterSaturday && workshop.isSaturdayWorking) ||
                (filterSunday && workshop.isSundayWorking);

            return matchesVehicle && matchesFilters;
        }
    );

    const [pop, setPop] = useState(false);
    const [popover, setPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setPop(true);
    };

    const handleClose = () => {
        setPop(false);
        setPopover(false);
        setAnchorEl(null);
    };

    useEffect(() => {
        getActiveWorkshops();
    }, [getActiveWorkshops, filterWeekday, filterSaturday, filterSunday]);

    return (
        <PageLayout animationData={dataAnimation}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75%',
                        mt: 16,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Container>
                    <Toolbar>
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: '600',
                                            color: '#002064',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Workshops
                                    </Typography>
                                </Box>

                                {/* <Box>
                                    <Button
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: '10px',
                                        }}
                                        onClick={handleFilterClick}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                color: '#002064',
                                                fontSize: '14px',
                                                textTransform: 'none',
                                            }}
                                        >
                                            Filter
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <img src={filterIcon} alt='/' />
                                        </Box>
                                    </Button>
                                </Box> */}
                            </Box>

                            <Box>
                                <Popover
                                    open={pop}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorReference='anchorEl'
                                    anchorEl={anchorEl}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingX: '10px',
                                            paddingY: '5px',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={filterWeekday}
                                                    onChange={(e) => {
                                                        setFilterWeekday(
                                                            e.target.checked
                                                        );
                                                        handleClose();
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontSize: '14px',
                                                        textAlign: 'left',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Weekdays
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={filterSaturday}
                                                    onChange={(e) => {
                                                        setFilterSaturday(
                                                            e.target.checked
                                                        );
                                                        handleClose();
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontSize: '14px',
                                                        textAlign: 'left',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Saturday
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={filterSunday}
                                                    onChange={(e) => {
                                                        setFilterSunday(
                                                            e.target.checked
                                                        );
                                                        handleClose();
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontSize: '14px',
                                                        textAlign: 'left',

                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Sunday
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </Popover>
                            </Box>
                        </Box>

                        {/* </Box> */}
                    </Toolbar>

                    <Box>
                        <Divider />

                        <Box>
                            {filteredWorkshops.length === 0 ? (
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        pt: { xs: '20px', lg: '50px' },
                                    }}
                                >
                                    No workshops found for{' '}
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {vehicleChosen}
                                    </span>
                                    .
                                </Typography>
                            ) : (
                                <SingleShopLists
                                    key={1}
                                    index={1}
                                    shopDetails={'54848484848'}
                                    workshopsData={workshopsData}
                                />
                            )}
                        </Box>

                        <FilterBanner
                            showActionSheet={showActionSheet}
                            setShowActionSheet={setShowActionSheet}
                            listedShops={listedShops}
                            setListedShops={setListedShops}
                            setFilterText={setFilterText}
                        />
                    </Box>
                    <Box mt={3}>
                        <IssueHelpButton />
                    </Box>
                </Container>
            )}
        </PageLayout>
    );
};

export default ShopDetail;
