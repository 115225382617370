export class UserDetailsModel {
    name: any;
    surname: any;
    phonenumber: any;
    email: any;
  
    constructor(obj: any) {
      this.name = obj.name;
      this.surname = obj.surname;
      this.phonenumber = obj.phonenumber;
      this.email = obj.email;
    }
  }