import { discontinuedData } from "../../components/discountinued/discountinued_data";
/**
 * Get manual make data from API.
 *
 * @param {Function} setMakes - A function to set vehicle makes.
 * @param {Function} setLoadingMake - A function to set loading state for makes.
 * @param {Function} setClicked - A function to set clicked state.
 * @param {any} gtMotorServices - The API object for fetching data.
 */

export const getManualMakeApi = (
  setMakes: any,
  setLoadingMake: any,
  setClicked: any,
  emsApiCall: any,
  bodyData: string
) => {
  setLoadingMake(true);
  emsApiCall(bodyData)
    .then((response: any) => {
      //! B's Code
      setMakes(response.data);
      setClicked(false);
      setLoadingMake(false);
    })
    .catch((e: any) => {
      setClicked(false);
    });
};

/**
 * Get manual model data from API.
 *
 * @param {Function} setLoadingModel - A function to set loading state for models.
 * @param {Function} setVariants - A function to set vehicle variants.
 * @param {Function} setClicked - A function to set clicked state.
 * @param {any} gtMotorServices - The API object for fetching data.
 */

export const getManualModelApi = (
  setLoadingModel: any,
  setVariants: any,
  setClicked: any,
  emsApiCall: any,
  bodyData: string
) => {
  setLoadingModel(true);
  emsApiCall(bodyData)
    .then((response: any) => {
      if (!response.model_class) {
        alert("Oops\nSomething went wrong please try changing your selection");
      } else {
        setVariants(response.model_class);
        setClicked(false);
        setLoadingModel(false);
      }
    })

    .catch((e: any) => {
      console.log("error", e);
      setClicked(false);
    });
};

export const getModelYearsApi = (
  setLoadingModel: any,
  setClicked: any,
  setVehicleYears: any,
  emsApiCall: any,
  bodyData: string
) => {
  setLoadingModel(true);
  emsApiCall(bodyData)
    .then((response: any) => {
      if (!response.model_years) {
        alert("Oops\nSomething went wrong please try changing your selection");
      } else {
        let modelYears = response.model_years;

        const currentYear = new Date().getFullYear();

        if (modelYears.length === 1) {
          const startYesr = modelYears[0];
          for (let year = startYesr + 1; year <= currentYear; year++) {
            modelYears.push(year);
          }
        } else {
          const minYears = Math.min(...modelYears);
          for (let year = minYears; year <= currentYear; year++) {
            if (!modelYears.includes(year)) {
              modelYears.push(year);
            }
          }
        }

        modelYears.sort((a: number, b: number) => a - b);

        setVehicleYears(modelYears);
        setClicked(false);
        setLoadingModel(false);
      }
    })

    .catch((e: any) => {
      setClicked(false);
    });
};

const fetchEngineDataForyear = async (
  emsApiCall: any,
  bodyData: string,
  year: number
) => {
  const previousBodyData = JSON.stringify({
    ...JSON.parse(bodyData),
    year: year,
  });
  const response = await emsApiCall(previousBodyData);
  return (
    response?.model_engine.map((engine: any) => ({ ...engine, year })) || []
  );
};

const filterDuplicatedEngines = (engineList: any[]) => {
  const uniqueEngines = engineList.reduce((eq, engine) => {
    const existingEngineIndex = eq.findIndex(
      (item: any) =>
        item.display === engine.display && item.fuel_type === engine.fuel_type
    );
    if (existingEngineIndex === -1) {
      eq.push(engine);
    } else {
      eq[existingEngineIndex] = engine;
    }
    return eq;
  }, []);
  return uniqueEngines;
};

const appendPreviousYearEngineData = async (
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number,
  engineData: any[]
) => {
  const sortedModelYears = [...modelYears].sort((a, b) => a - b);
  const startYear = sortedModelYears.indexOf(currentYear) - 1;
  for (let i = startYear; i >= 0; i--) {
    const previousYear = sortedModelYears[i];
    const previousYearEngineData = await fetchEngineDataForyear(
      emsApiCall,
      bodyData,
      previousYear
    );
    engineData.push(...previousYearEngineData);
    // previousYearEngineData.forEach((previousEngine: any) => {
    //   if (
    //     !engineData.some(
    //       (engine: any) =>
    //         engine.displacement === previousEngine.displacement &&
    //         engine.fuel_type === previousEngine.fuel_type
    //     )
    //   ) {
    //     engineData.push(previousEngine);
    //   }
    // });
    // if (engineData.length > 1) {
    //   break;
    // }
  }
};

// const groupEngines = (engines: any[]) => {
//   const grouped: any[] = [];
//   const map = new Map<string, boolean>();
//   for (const engine of engines) {
//     const key = `${engine.display}-${engine.fuel_type}`;
//     if (!map.has(key)) {
//       map.set(key, true);
//       grouped.push(engine);
//     }
//   }
//   return grouped;
// };

const groupEgnines = (engines: any[]) => {
  const grouped: any[] = [];
  const map = new Map<string, boolean>();

  for (const engine of engines) {
    const key = `${engine.display}-${engine.fuel_type}`;
    if (!map.has(key)) {
      map.set(key, true);
      grouped.push(engine);
    }
  }
  return grouped;
};

export const getModelEngineDataApi = async (
  setLoadingEngine: any,
  setClicked: any,
  setEngineList: any,
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  try {
    let response = await emsApiCall(bodyData);
    let engineData = response?.model_engine || [];

    await appendPreviousYearEngineData(
      emsApiCall,
      bodyData,
      modelYears,
      currentYear,
      engineData
    );

    // if (engineData.length === 0) {
    //   await appendPreviousYearEngineData(
    //     emsApiCall,
    //     bodyData,
    //     modelYears,
    //     currentYear,
    //     engineData
    //   );
    // } else if (engineData.length === 1) {
    //   await appendPreviousYearEngineData(
    //     emsApiCall,
    //     bodyData,
    //     modelYears,
    //     currentYear,
    //     engineData
    //   );
    // }

    // if (engineData.length <= 1) {
    //   await appendPreviousYearEngineData(
    //     emsApiCall,
    //     bodyData,
    //     modelYears,
    //     currentYear,
    //     engineData
    //   );
    // }

    const allGroupedEngines = groupEgnines(engineData);
    setEngineList(allGroupedEngines);
  } catch (error) {
    console.log(
      "Oops\nSomething went wrong please try changing your selection",
      error
    );
  }
  setLoadingEngine(false);
  setClicked(false);
};

const fetchGearboxTypeForPreviousYear = async (
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  const sortedModelYears = [...modelYears].sort((a, b) => a - b);
  let allGearBoxTypes: string[] = [];

  for (let i = 0; i < sortedModelYears.length; i++) {
    const previousYear = sortedModelYears[i];
    if (previousYear > currentYear) continue;

    const previousBodyData = JSON.stringify({
      ...JSON.parse(bodyData),
      year: previousYear,
    });

    const response = await emsApiCall(previousBodyData);
    const previousYearData = response?.model_gearbox_types || [];

    previousYearData.forEach((gearBoxType: string) => {
      if (!allGearBoxTypes.includes(gearBoxType)) {
        allGearBoxTypes.push(gearBoxType);
      }
    });
  }
  return allGearBoxTypes;
};

export const getModelGearboxTypeDataApi = async (
  setLoadingGearboxType: any,
  setClicked: any,
  setGearboxTypeList: any,
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  setLoadingGearboxType(true);
  try {
    let response = await emsApiCall(bodyData);
    let gearBoxTypeData = response?.model_gearbox_types || [];

    const previousYearData = await fetchGearboxTypeForPreviousYear(
      emsApiCall,
      bodyData,
      modelYears,
      currentYear
    );
    gearBoxTypeData = [...gearBoxTypeData, ...previousYearData].filter(
      (value, index, self) => self.indexOf(value) === index
    );

    setGearboxTypeList(gearBoxTypeData);
  } catch (error) {
    console.log(
      "Oops\nSomething went wrong please try changing your selection",
      error
    );
  } finally {
    setLoadingGearboxType(false);
    setClicked(false);
  }
};

const fetchGearboxDriveTypeForPreviousYear = async (
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  const sortedModelYears = [...modelYears].sort((a, b) => a - b);
  let allGearBoxDriveTypes: string[] = [];

  for (let i = sortedModelYears.indexOf(currentYear); i >= 0; i--) {
    const previousYear = sortedModelYears[i];
    const previousBodyData = JSON.stringify({
      ...JSON.parse(bodyData),
      year: previousYear,
    });

    const response = await emsApiCall(previousBodyData);
    const previousYearData = response?.model_drive_types || [];

    previousYearData.forEach((driveType: string) => {
      if (!allGearBoxDriveTypes.includes(driveType)) {
        allGearBoxDriveTypes.push(driveType);
      }
    });
  }
  return allGearBoxDriveTypes;
};

export const getModelGearboxDriveTypeApi = async (
  setLoadingGearboxDrive: any,
  setClicked: any,
  setGearboxDriveList: any,
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  setLoadingGearboxDrive(true);
  try {
    let response = await emsApiCall(bodyData);
    let gearBoxDriveData = response?.model_drive_types || [];

    const previousYearData = await fetchGearboxDriveTypeForPreviousYear(
      emsApiCall,
      bodyData,
      modelYears,
      currentYear
    );

    gearBoxDriveData = [...gearBoxDriveData, ...previousYearData].filter(
      (value, index, self) => self.indexOf(value) === index
    );

    setGearboxDriveList(gearBoxDriveData);
    setClicked(false);
  } catch (error) {
    console.log(
      "Oops\nSomething went wrong please try changing your selection",
      error
    );
  } finally {
    setLoadingGearboxDrive(false);
    setClicked(false);
  }
};

const fetchVariantDataForPreviousYear = async (
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  const sortedModelYears = [...modelYears].sort((a, b) => a - b);
  let allVariants: any[] = [];

  for (let i = sortedModelYears.indexOf(currentYear); i >= 0; i--) {
    const previousYear = sortedModelYears[i];

    const discontinueInfo = discontinuedData.find(
      (item: any) =>
        item.MM_CODES === JSON.parse(bodyData).cc2 &&
        new Date(item.Discontinued_Date).getFullYear() <= previousYear
    );

    if (
      discontinueInfo &&
      currentYear > new Date(discontinueInfo.Discontinued_Date).getFullYear()
    ) {
      console.log(`model discontinued: ${previousYear}`);
      continue;
    }

    const previousBodyData = JSON.stringify({
      ...JSON.parse(bodyData),
      year: previousYear,
    });

    const response = await emsApiCall(previousBodyData);
    const previousYearData = response?.model_details?.all_models || [];

    previousYearData.forEach((variant: any) => {
      if (!allVariants.some((v) => v.umc === variant.umc)) {
        allVariants.push(variant);
      }
    });
  }
  return allVariants;
};

const filterMostCurrentVariants = (variants: any[]): any[] => {
  const latestVariants: Record<string, any> = {};

  variants.forEach((variant) => {
    const key = variant.cc2 || variant.umc;
    if (
      !latestVariants[key] ||
      !variant.cc2 ||
      new Date(latestVariants[key].intro_date) < new Date(variant.intro_date)
    ) {
      latestVariants[key] = variant;
      console.log("Variant: ", latestVariants);
    }
  });
  console.log("final variant: ", latestVariants);
  return Object.values(latestVariants);
};

export const getModelDetailsApi = async (
  setLoadingModelDetail: any,
  setClicked: any,
  setModelDetail: any,
  emsApiCall: any,
  bodyData: string,
  modelYears: number[],
  currentYear: number
) => {
  setLoadingModelDetail(true);
  try {
    let response = await emsApiCall(bodyData);
    let variantData = response?.model_details?.all_models || [];

    const previousYearData = await fetchVariantDataForPreviousYear(
      emsApiCall,
      bodyData,
      modelYears,
      currentYear
    );
    variantData = [...variantData, ...previousYearData].filter(
      (value, index, self) =>
        self.findIndex((v) => v.umc === value.umc) === index
    );

    const filterVariant = filterMostCurrentVariants(variantData);
    // console.log("filtered variant: ", filterVariant);

    const allValidVariants = filterVariant.filter((variant: any) => {
      const discountinuedDates = discontinuedData.find(
        (item: any) => item.MM_CODES === parseInt(variant.cc2)
      );

      if (!discountinuedDates || !discountinuedDates["Discontinued_Date"]) {
        return true;
      }

      const discountinueDate = new Date(
        discountinuedDates["Discontinued_Date"]
      );
      const introDate = new Date(variant.intro_date);

      const modelIsStillValid = currentYear <= discountinueDate.getFullYear();

      console.log(
        `Variant: ${variant.variant}, Intro Date: ${introDate}, Discontinue Date: ${discountinueDate}, is Still valid: ${modelIsStillValid}`
      );

      return introDate.getFullYear() <= currentYear && modelIsStillValid;
    });
    console.log("filtering properly: ", allValidVariants);
    setModelDetail(allValidVariants);
  } catch (error) {
    console.log(
      "Oops\nSomething went wrong please try changing your selection",
      error
    );
  } finally {
    setLoadingModelDetail(false);
    setClicked(false);
  }
};

// export const getModelEngineDataWithPreviousYears = (setLoadingModelDetail: any, setClicked: any, setModelDetail: any ) => {

// }

/**
 * Calculates the age of a vehicle based on the given year and updates related data.
 *
 * @param {number} year - The manufacturing year of the vehicle.
 * @param {Object} maintenanceEstimateData - The maintenance estimate data object.
 */
export const calculateVehicleAge = (
  year: number,

  setSelectedModelAge: any
) => {
  const currentYear = new Date().getFullYear();
  const vehicleAge = currentYear - year;
  setSelectedModelAge(vehicleAge);
};

/**
 * Custom make data for selection.
 */
export const customMake = {
  header: "Select Make",
  subHeader: "Scroll to select your vehicle make.",
};

/**
 * Custom model data for selection.
 */
export const customModel = {
  header: "Select Model",
  subHeader: "Scroll to select your vehicle model.",
};

/**
 * Custom year data for selection.
 */
export const customYear = {
  header: "Select Engine Option",
  subHeader: "Scroll to select your engine option.",
};

/**
 * Interface year data for selection.
 */
export const interfaceYear = {
  header: "Select Year",
  subHeader: "Scroll to select your year.",
};

/**
 * Custom gearbox data for selection.
 */
export const customGear = {
  header: "Select Gearbox Option",
  subHeader: "Scroll to select your gearbox option.",
};
