import { IonContent } from '@ionic/react';
import Looking from '../../../assets/icons/looking.svg';
import activeBrand from '../../../assets/nissan.svg';
import { Container, Box, Typography } from '@mui/material';
import { ConfirmDisplay } from '../../../components/common/ConfirmDisplay';
import IssueHelpButton from '../../../components/common/IssueHelpButton';
/**
 * Main content component for confirmation page
 *
 * @returns JSX
 */
// {vehicles,vehicleInfo,activeBrand}:{vehicles:any, vehicleInfo:any,activeBrand:any}
export const BodyComponent = () => {
    const vehicleInfo = JSON.parse(
        localStorage.getItem('vehicle-details') ?? ''
    );
    // const vehicles = JSON.parse(localStorage.getItem("vehicles") ?? "");

    return (
        <Container
            disableGutters
            sx={{
                width: { xs: 'auto', lg: '100%' },
                marginTop: { xs: '-10x', lg: '0px' },
                zIndex: '999',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    pt: { xs: '40px', lg: '40px' },
                    pr: { xs: '0', lg: '40px' },
                    paddingBottom: { xs: '100px', lg: '0' },
                }}
            >
                {/* {activeBrand ? (
            <img
              src={activeBrand}
              className="small-logo"
              alt="brand_logo"
            />
          ) : null} */}

                <ConfirmDisplay headerText='Vehicle'>
                    {vehicleInfo.vehicle_make} {vehicleInfo.model_class}
                </ConfirmDisplay>

                <ConfirmDisplay headerText='Engine'>
                    {`${vehicleInfo.displayDisplacement} L`}{' '}
                </ConfirmDisplay>

                <ConfirmDisplay headerText='Gearbox Type'>
                    {vehicleInfo.transmission}
                </ConfirmDisplay>

                <ConfirmDisplay headerText='Registration No.'>
                    {vehicleInfo.registration_number}
                </ConfirmDisplay>

                <ConfirmDisplay headerText='Mileage'>
                    {`${vehicleInfo.vehicleMilleage} Km`}
                </ConfirmDisplay>

                <Box mt={{ xs: 3, lg: 2 }}>
                    <IssueHelpButton />
                </Box>
            </Box>
        </Container>
    );
};
